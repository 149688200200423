import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import favicon from "../images/favicon.ico"

import heroImg from "../images/marketing/svg/pedigree-request/hero.svg"
import requestimg from "../images/marketing/svg/pedigree-request/request-illus.svg"
import uploadimg from "../images/marketing/svg/pedigree-request/upload-illus.svg"
import printimg from "../images/marketing/svg/pedigree-request/print-illus.svg"

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        Stay DSCSA Compliant with T3 Pedigree Requests - EzriRx.com -
        Marketplace Platform for 65,000+ Pharmaceuticals
      </title>
      <meta
        name="keywords"
        content="ezrirx, pedigree requests, pharmacy purchasing platform"
      />
      <meta
        name="description"
        content="EzriRx allows pharmacies to stay DSCSA Compliant with T3 Pedigree Requests. With each order, requests are sent to wholesalers for transaction history."
      />
      <link rel="canonical" href="https://www.ezrirx.com/DSCSA-compliance/" />
      <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
    </Helmet>
    <Layout>
      <div className="body">
        <section className="hero-banner" id="hero-pedigree-request">
          <div className="contents">
            <div className="hero-banner__inner">
              <div className="hero-content">
                <h1>Electronic &amp; PDF DSCSA Transaction Documents</h1>
                <p>Stay DSCSA Compliant with T3 Pedigree Requests</p>
              </div>
              <div className="hero-image">
                <img
                  src={heroImg}
                  alt="Electronic & PDF DSCSA Transaction Documents"
                ></img>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content with-border">
          <div className="contents">
            <div className="col single-col">
              <div className="title-box">
                <h2>How it all works</h2>
              </div>
              <p>
                Pharmacies can ensure DSCSA Compliance with one click of a
                button. With each order, requests are sent to wholesalers for
                transaction history. EzriRx hosts and stores these documents for
                your access when needed.
              </p>
            </div>

            <div className="table-2cols with-padding-top">
              <div className="col col--text text-left-mobile">
                <div className="title-box">
                  <h2>Manual or Automatic T3 Requests</h2>
                </div>
                <p>
                  Your requests for documentation can be done manually by you,
                  or automatically for each order if you choose. Once requested,
                  wholesalers will upload PDF documents for your viewing at any
                  time.
                </p>
              </div>
              <div className="col col--img text-center">
                <img
                  src={requestimg}
                  alt="Manual or Automatic T3 Requests"
                ></img>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content with-border">
          <div className="contents">
            <div className="table-2cols table-2cols--mobile">
              <div className="col col--img text-center">
                <img
                  src={uploadimg}
                  alt="Download, Print & Save Pedigree Documents"
                ></img>
              </div>
              <div className="col col--text text-left-mobile">
                <div className="title-box">
                  <h2>Download, Print &amp; Save Pedigree Documents</h2>
                </div>
                <p>
                  Within the Document section on EzriRx, pharmacies can view and
                  print T3 history as needed.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content with-border">
          <div className="contents">
            <div className="table-2cols">
              <div className="col col--text text-left-mobile">
                <div className="title-box">
                  <h2>Track and Trace</h2>
                </div>
                <p>
                  Know where your pharmaceuticals come from and make sure
                  compliance is met in case of an audit. T3 Documents are
                  crucial for keeping track of prescription drugs as they are
                  sold and distributed in the United States.
                </p>
              </div>
              <div className="col col--img text-center">
                <img src={printimg} alt="Track and Trace"></img>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  </>
)
